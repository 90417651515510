import { normalizeSync } from 'normalize-diacritics';

function isEmpty(value: string): boolean {
  return value === null || value === undefined || value === "";
}

export const setFavicon = (path: string, faviconName: string): void => {
  const favicon = document.getElementById(faviconName) as HTMLLinkElement;
  if (favicon) {
    favicon.href = path;
  }
};

export const queryParams = (search: string): any => {
  return search
    .slice(1)
    .split("&")
    .reduce((acc, s) => {
      const [k, v] = s.split("=");
      return Object.assign(acc, { [k]: v });
    }, {});
};

export const changeRgbaOpacity = (
  rgbaString: string,
  opacity: number
): string => {
  const rgbaSplit = rgbaString.split(",");
  const rgbaSplitWithoutOpacity = rgbaSplit.slice();
  rgbaSplitWithoutOpacity.splice(-1, 1);
  const opacityStr = `${opacity})`;

  const colorWithOpacity = rgbaSplitWithoutOpacity.join(",") + `,${opacityStr}`;
  return colorWithOpacity;
};

export const HelperFunctions = {
  assignInputClass: function (str: string): string {
    return !isEmpty(str) ? "input-filled" : "input-empty";
  },
  isEmpty: function (str: string): boolean {
    return isEmpty(str);
  },
  emailIsValid: function (emailStr: string): boolean {
    if (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(emailStr)) {
      return true;
    } else {
      return false;
    }
  },
  postalCodeIsValid: function (postalCodeStr: string): boolean {
    return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postalCodeStr);
  },
  dateIsValid: function (dateStr: string): boolean {
    let dts = dateStr.split("/"),
      dateTest = new Date(dateStr);
    return (
      dateTest.getFullYear() === parseInt(dts[2], 10) &&
      dateTest.getMonth() === parseInt(dts[0], 10) - 1 &&
      dateTest.getDate() === parseInt(dts[1], 10)
    );
  },
  phoneIsValid: function (value: string): boolean {
    return /^\(?[2-9]{1}[0-9]{2}\)?\s?[0-9]{3}\-?[0-9]{4}/.test(value);
  },
  getAgeFromDateOfBirth: function (dateStr: string): number {
    let today = new Date();
    let birthDate = new Date(dateStr);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },
  setPageTitle: function (pageTitle: string): void {
    document.title = pageTitle;
  },
  formatCurrency: function (amount: number, minFractionDigits?: number,
    maxFractionDigits?: number, language?: "en" | "fr"): string {
    let locale: "en-CA" | "fr-CA";
    switch (language) {
      case "en":
        locale = "en-CA";
        break;
      case "fr":
        locale = "fr-CA";
        break;
      default:
        locale = "en-CA";
        break;
    }
    return Intl.NumberFormat(locale, {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits: minFractionDigits ?? 0,
      maximumFractionDigits: maxFractionDigits ?? 2,
    }).format(amount || 0);
  },
  formatPercentage: function (value?: number): string {
    return Intl.NumberFormat("en-CA", {
      style: "percent",
      maximumFractionDigits: 2,
    }).format((value || 0) / 100);
  },
  formatDate: function (
    date: string,
    onlydatepart: boolean,
    datedelimiter: string
  ): string {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minutes = d.getMinutes(),
      seconds = d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let datePart = [month, day, year].join(datedelimiter);
    if (onlydatepart) return `${datePart}`;

    let timePart = [hour, minutes, seconds].join(":");
    return `${datePart} ${timePart} PST`;
  },
  scrollToTop: function (delayInMs: number, scrollStepInPx: number) {
    let intervalId = setInterval(function () {
      if (window.pageYOffset === 0) {
        clearInterval(intervalId);
      }
      window.scroll(0, window.pageYOffset - scrollStepInPx);
    }, delayInMs);
  },
  removeDiacritics: function (str: string): string {
    return normalizeSync(str);
  },
  replaceAmpersands: function (string: string): string {
    return string.replace('&', ';amp');
  }
};
