import React, { useCallback, useEffect, useState } from "react";
import { Styles, AppState, LocalSettings } from "../configuration/AppConfig";
import { DocumentPageUploadFile } from "./DocumentPageUploadFile";
import {
  DocumentPageAction,
  DocumentPageActionProps,
} from "./DocumentPageAction";
import { RemainingItem } from "../store/Store";
import { useMappedState, useDispatch, ActionType } from "../store/Store";
import LoadingScreen from "./LoadingScreen";
import Background from "./Background";
import "./DocumentPage.css";
import { Button } from "reactstrap";
import { HelperFunctions } from "../utils/HelperFunctions";
import ModalDialog from "./ModalDialog";
import TermsAndConditions from "./TermsAndConditions";
import { useParams } from "react-router-dom";
import Api from "../utils/Api";
import TagManager from "react-gtm-module";
import queryString from "query-string";
import ReactGA from "react-ga";

export default function DocumentPage() {
  const styles = {
    root: {
      maxWidth: "600px",
      margin: "auto",
    },
    main: {
      width: "100%",
      textAlign: "center",
      padding: "10px 0px 10px 0px",
    },
    title: {
      textAlign: "center",
    },
    subtitle: {
      textAlign: "left",
    },
    uploadItem: {
      margin: 10,
    },
    uploadItemPadding: {
      padding: 20,
      position: "relative",
      boxShadow:
        "0px 5px 4px rgba(0, 0, 0, 0.07), 0px 15px 35px rgba(50, 50, 93, 0.1)",
      borderRadius: 5,
    },
    details: {
      maxWidth: 450,
      textAlign: "left",
    },
    body1: {
      fontSize: 15,
    },
    caption: {
      fontSize: 14,
    },
    menu: {
      height: 100,
      backgroundColor: Styles.menuColor,
      position: "relative",
    },
    homeLink: {
      top: 38,
      left: 50,
      position: "absolute",
      color: Styles.defaultColor,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    mainTitle: {
      color: Styles.textColorPrimary,
      textAlign: "center",
      fontSize: 24,
      fontWeight: "bold",
    },
    tools: {
      position: "absolute",
      right: 50,
      top: 38,
    },
    toolItem: {
      marginLeft: 20,
      marginRight: 20,
    },
    submitButton: {
      backgroundColor: Styles.textColorPrimary,
      borderColor: Styles.textColorPrimary,
      color: Styles.defaultColor,
      borderRadius: 30,
      width: 260,
      margin: 30,
      fontSize: "1rem",
    },
  };
  const { applicationId } = useParams<{ applicationId: string }>();
  const dispatch = useDispatch();
  const data = useDocumentsPage();

  const documentsTranslation = LocalSettings.translation.documentsPage;

  const setReadyState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.READY }),
    [dispatch]
  );
  const setErrorState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.ERROR }),
    [dispatch]
  );

  const setDocuments = useCallback(
    (value: RemainingItem[]) =>
      dispatch({ type: ActionType.CHANGE_DOCUMENTS, value: value }),
    [dispatch]
  );

  let queryParams = queryString.parse(window.location.search);
  const userId = queryParams.userId || null;
  const companyName = queryParams.companyName || null;
  const companyNameStr = companyName ? `&companyName=${companyName}`: '';

  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [bankStatus, setBankStatus] = useState<string>("not_started");
  const [appSubmitted, setAppSubmitted] = useState(false);
  const [filesOnlyMode, setFilesOnlyMode] = useState(false);

  if (!applicationId) throw new Error("Application id should be provided!");

  useEffect(() => {
    HelperFunctions.setPageTitle(
      `${documentsTranslation.pageName} - ${LocalSettings.companyName}`
    );
    const input = {
      type: "Self_Remaining_Items",
      language: LocalSettings.language,
      applicationId: applicationId,
    };

    ReactGA.initialize("UA-15983938-3");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const tagManagerArgs = {
      gtmId: "GTM-KW3ZJC7",
    };
    TagManager.initialize(tagManagerArgs);

    Api.getApplication(
      input,
      (response: any) => {
        setDocuments(response.data.items);
        setBankStatus(response.data.bankStatus);
        setAppSubmitted(response.data.isSubmitted);
        setFilesOnlyMode(response.data.filesOnlyMode);
        data.handleChangeManual("consent", response.data.consent);
        setTimeout(() => {
          setReadyState();
        }, 1500);
      },
      (error: any) => {
        alert(JSON.stringify(error));
        setErrorState();
      }
    );
  }, []);

  return <div>{renderDocumentsPage()}</div>;

  function submit() {
    window.location.href = `/finish?language=${LocalSettings.language}` + addUserId("&") + companyNameStr;
  }

  function addUserId(symbol: "&" | "?") {
    return userId === null ? "" : `${symbol}userId=${userId}`;
  }

  function renderDocumentsPage() {
    if (!applicationId) throw new Error("Application id should be provided!");

    let actions: DocumentPageActionProps[] = [];

    if (!filesOnlyMode) {
      actions.push({
        itemName: documentsTranslation.businessInfo,
        description: documentsTranslation.actionBusinessInformation,
        status: appSubmitted
          ? documentsTranslation.completedButtonText
          : documentsTranslation.requestedButtonText,
        action: () => {
          window.location.href =
            `/application/${applicationId}?language=${LocalSettings.language}&redirectPage=documents${companyNameStr}` +
            addUserId("&");
        },
      });
      actions.push({
        itemName: documentsTranslation.termsAndConditions,
        description: documentsTranslation.actionTermsAndConditions,
        status: data.consent
          ? documentsTranslation.completedButtonText
          : documentsTranslation.requestedButtonText,
        action: () => {
          setTermsDialogOpen(true);
        },
      });

      let bankConnectStatusText: string;
      let description: string;

      switch (bankStatus) {
        case "not_started":
          bankConnectStatusText = documentsTranslation.requestedButtonText;
          description = documentsTranslation.actionConnectBank;
          break;
        case "connected":
          bankConnectStatusText = documentsTranslation.completedButtonText;
          description = documentsTranslation.actionBankConnectedText;
          break;
        case "not_listed":
          bankConnectStatusText = documentsTranslation.requestedButtonText;
          description = documentsTranslation.actionBankNotListed;
          break;
        case "paper_statements":
          bankConnectStatusText = documentsTranslation.requestedButtonText;
          description = documentsTranslation.actionPaperStatements;
          break;
        case "in_progress":
          bankConnectStatusText = documentsTranslation.continueButtonText;
          description = documentsTranslation.actionContinueConnectingBank;
          break;
        default:
          throw new Error(`Unknown status: '${bankStatus}'`);
      }

      actions.push({
        itemName: documentsTranslation.connectBank,
        description: description,
        status: bankConnectStatusText,
        action: () => {
          window.location.href =
            `/banking/${applicationId}?language=${LocalSettings.language}${companyNameStr}` +
            addUserId("&");
        },
      });
    }

    return (
      <div>
        <div
          style={{
            display:
              data.applicationState === AppState.LOADING ? "block" : "none",
          }}
        >
          <LoadingScreen />
        </div>
        <div
          style={{
            display:
              data.applicationState === AppState.READY ? "block" : "none",
          }}
        >
          <Background
            image="stepper"
            useTemplate={true}
            useFooter={true}
            useSvg={true}
          >
            <div style={{ textAlign: "center" }}>
              <h2
                style={{
                  color: Styles.textColorPrimary,
                  textAlign: "center" as "center",
                }}
              >
                {documentsTranslation.mainTitle}
              </h2>
              <h4
                style={{
                  color: Styles.textColorDescription,
                  textAlign: "center" as "center",
                }}
              >
                {documentsTranslation.secondaryTitle}
              </h4>
              {actions.map((item, index) => {
                return (
                  <div style={styles.uploadItem}>
                    <DocumentPageAction
                      key={`action_item_${index}`}
                      itemName={item.itemName}
                      description={item.description}
                      status={item.status}
                      action={item.action}
                    ></DocumentPageAction>
                  </div>
                );
              })}
              {data.documents.map((item, index) => {
                return (
                  <div style={styles.uploadItem}>
                    <DocumentPageUploadFile
                      key={`document_item_${index}`}
                      externalId={item.externalId}
                      itemName={item.itemName}
                      status={item.status}
                      description={item.description}
                    ></DocumentPageUploadFile>
                  </div>
                );
              })}
              <Button
                color="primary"
                size="lg"
                id="documents_submit"
                style={styles.submitButton}
                onClick={() => {
                  submit();
                }}
              >
                {documentsTranslation.submitButtonText}
              </Button>
              <ModalDialog
                title={documentsTranslation.termsAndConditions}
                textStyle={{ color: "#fff", fontWeight: 300, fontSize: "20px" }}
                agreeText={documentsTranslation.tcAgree}
                disagreeText={documentsTranslation.tcCancel}
                handleClose={() => setTermsDialogOpen(false)}
                handleAgreed={() => {
                  data.handleChangeManual("consent", true);
                  const consentInput = {
                    type: "Self_Update_Consent",
                    applicationId: applicationId,
                    consent: true,
                  };
                  Api.getApplication(
                    consentInput,
                    (response: any) => {},
                    (error: any) => {
                      alert(JSON.stringify(error));
                      setErrorState();
                    }
                  );
                  setTermsDialogOpen(false);
                }}
                handleDisagreed={() => setTermsDialogOpen(false)}
                hideCloseButton={false}
                contentStyle={{ backgroundColor: Styles.textColorPrimary }}
                buttonAgreeStyle={{
                  backgroundColor: Styles.textColorSecondaryAlt,
                  borderColor: Styles.textColorSecondaryAlt,
                  color: Styles.defaultColor,
                  boxShadow: "unset",
                }}
                buttonDisagreeStyle={{
                  backgroundColor: Styles.textColorSecondaryAlt,
                  borderColor: Styles.textColorSecondaryAlt,
                  color: Styles.defaultColor,
                  boxShadow: "unset",
                }}
                open={termsDialogOpen}
              >
                <TermsAndConditions textStyle={{ color: "#fff" }} />
              </ModalDialog>
            </div>
          </Background>
        </div>
      </div>
    );
  }

  function useDocumentsPage(): DocumentPageState {
    const {
      documents,
      applicationState,
      bankAuthorized,
      bankInProcess,
      input,
    } = useMappedState((state) => state);
    const handleChangeManual = useCallback(
      (name: string, value: any) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: name,
        }),
      [dispatch, input.consent]
    );
    return {
      applicationState: applicationState,
      documents: documents,
      consent: input.consent,
      bankAuthorized: bankAuthorized,
      bankInProcess: bankInProcess,
      handleChangeManual: handleChangeManual,
    };
  }
}

type DocumentPageState = {
  applicationState: AppState;
  consent: boolean;
  bankAuthorized: boolean;
  bankInProcess: boolean;
  documents: RemainingItem[];
  handleChangeManual: Function;
};
